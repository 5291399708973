import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../../node_modules/bootstrap/dist/js/bootstrap.min";
const AppLayout = () => {
  return (
    <div
      style={{
        backgroundColor: "#fafafa",
        position: "relative",
        height: "100vh",
        overflow: "auto",
      }}
    >
      {/* {data ? "" : <Header />} */}

      <div className="">
        <Outlet />
      </div>
      <Footer />
      {/* <RegionsModal show={regionModalShow} /> */}
    </div>
  );
};

export default AppLayout;
