import React, { memo } from "react";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import { QUESTIONNAIRE_RESULT, uploadImageUrl } from "../../constants";
import "./survey.css";

//  Survey.StylesManager.applyTheme("modern");
var defaultThemeColors = Survey.StylesManager.ThemeColors["default"];
defaultThemeColors["$main-color"] = "#061b7f";
defaultThemeColors["$main-hover-color"] = "#410153";
Survey.StylesManager.applyTheme();

// var defaultThemeColors = Survey.StylesManager.ThemeColors["default"];

// defaultThemeColors["$main-color"] = "#061b7f";
// defaultThemeColors["$border-color"] = "#061b7f";
// defaultThemeColors["$main-hover-color"] = "#061b7f";
// defaultThemeColors["$text-color"] = "#fffff";
// defaultThemeColors["$header-color"] = "#061b7f";

// defaultThemeColors["$header-background-color"] = "#dedede";
// defaultThemeColors["$body-container-background-color"] = "#f5f5f5";

// Survey.StylesManager.applyTheme(defaultThemeColors);
const SurveyCreatorComponent = ({
  questionnaireJson,
  handleComplete,
  answers,
  isEdit,
  handleStart,
  handleSetQuestion,
  onCompletingHandle,
  handleStarted,
  webClientSetting,
  closeQuestionnaire,
  projectName,
}) => {
  console.log("answers==>", answers);
  // useEffect(() => {
  //   if (closeQuestionnaire) {
  //     survey.doComplete();
  //     survey.onComplete.add((sender, res) => {});
  //   }
  // }, [closeQuestionnaire]);

  // useEffect(() => {
  //   setStartCountDown(true);
  // }, []);

  function updateFileQuestions(questionnaireJson) {
    // Loop through each page in the questionnaire
    questionnaireJson?.pages?.forEach((page) => {
      // Loop through each element on the page
      page.elements.forEach((element) => {
        // Check if the element is of type "file"
        if (element.type === "file") {
          // Update the properties for file upload questions
          element.storeDataAsText = false;
          element.waitForUpload = true;
        }
      });
    });
    return questionnaireJson;
  }

  const survey = new Survey.Model(updateFileQuestions(questionnaireJson));
  survey.onValueChanged.add(function (res, op) {
    let data = {
      answerJSON: res.data,
    };
    let stringify = JSON.stringify(data);
    localStorage.setItem(QUESTIONNAIRE_RESULT, stringify);
  });

  survey.onUploadFiles.add(async (survey, options) => {
    // Get the selected file
    const file = options.files[0];

    // Create a form data object to send the file
    const formData = new FormData();
    formData.append("file", file);
    formData.append("projectName", `${projectName}/Images`);

    try {
      // Send a POST request to your server to upload the file
      const response = await fetch(uploadImageUrl, {
        method: "POST",
        body: formData,
      });
      if (!response.ok) {
        throw new Error("Failed to upload file");
      }
      const result = await response.json();
      // Assuming your server returns the uploaded file's URL as `fileUrl`
      const fileUrl = result.url;
      // Set the link to the uploaded image in SurveyJS
      options.callback("success", [
        {
          file: file,
          content: fileUrl, // Provide only the URL string here
        },
      ]);
    } catch (error) {
      console.error("File upload error:", error);
      options.callback("error");
    }
  });

  survey.data = answers;
  survey.mode = isEdit ? "" : "display";

  if (webClientSetting?.surveyExpireMessage) {
    survey.completedHtml = "-";
  }

  return (
    <div className="w-100 p-2">
      <Survey.Survey
        onAfterRenderQuestion={handleStart}
        onCurrentPageChanged={handleSetQuestion}
        onStarted={handleStarted}
        model={survey}
        onCompleting={onCompletingHandle}
        onComplete={handleComplete}
      />
    </div>
  );
};
export default memo(SurveyCreatorComponent);
