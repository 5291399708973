import { Skeleton } from '@mui/material';
import React from 'react'

const CardSkeleton = () => {
  return (
    <div className="project-detail-card bg-white shadow px-3 py-3 d-flex flex-column justify-content-around align-items-start">
    <div className='w-100 d-flex justify-content-between align-items-center p-2'>
    <Skeleton animation="wave"  width={200} height={40} />
    <Skeleton animation="wave"  width={40} height={40} />
    </div>
    <div className='project-description text-muted p-2'>
      <p className='my-0'>    <Skeleton animation="wave"  width={120} height={20} /></p>
      <p className='my-0'><Skeleton animation="wave"  width={120} height={20} /></p>
      <p className='my-0'><Skeleton animation="wave"  width={120} height={20} /></p>
      <p className='my-0'><Skeleton animation="wave"  width={120} height={20} /></p>
    </div>
    <div className='p-2 project-footer-info w-100 d-flex justify-content-between aling-items-center'>
      <span> <Skeleton animation="wave"  width={150} height={30} /></span>
      <span className='text-primary'> <Skeleton animation="wave"  width={80} height={30} /></span>
    </div>
</div>  )
}

export default CardSkeleton;