import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import "./style.css";
import { GlobalContext } from "../../global_context";
import ChartSummary from "../../components/ProductDashboard/ChartSummary/ChartSummary";
import ChartData from "../../components/ProductDashboard/ChartData/ChartData";
import ManageVideo from "../../components/ManageVideo/ManageVideo";
import {
  fetchFilteredDocumentByLevels,
  fetchProjectWavesForDropDown,
  getFiltersSetting,
  getProjectSection,
} from "../../apis";
import { useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Margin, usePDF } from "react-to-pdf";
import SurveyCreatorComponent from "../../components/Survey";

//tabs code starts
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import QuestionMR from "../../components/QuestionMR/QuestionMR";
import QuestionInputTab from "../../components/QuestionInputTab/QuestionInputTab";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
//tabs code ends

// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function ProjectDashboard() {
  const { auth } = useContext(GlobalContext);
  console.log("incoming auth", auth);
  const { id: projectId } = useParams();
  const [section, setSection] = useState([]);

  //tab 2 states start
  const [selectedWave2, setSelectedWave2] = useState([]);
  const [counterMeasureReport, setCounterMeasureReport] = useState();
  const [
    selectedGeneralDetailFilterValues2,
    setSelectedGeneralDetailFilterValues2,
  ] = useState({});
  //tab 2 states end

  //tab 3 states start
    const [selectedWave3, setSelectedWave3] = useState("");
    const [
      selectedGeneralDetailFilterValues3,
      setSelectedGeneralDetailFilterValues3,
    ] = useState({});
  //tab 3 states end

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [questionnaireJson, setQuestionnaireJson] = useState([]);
  const [levelsDropdown, setLevelsDropdown] = useState();
  const [
    selectedGeneralDetailFilterValues,
    setSelectedGeneralDetailFilterValues,
  ] = useState({});
  const [chartData, setChartData] = useState(null);
  const [answerJson, setAnswerJson] = useState("");
  const [mediaUrl, setMediaUrl] = useState([]);
  const [qV, setQV] = useState([]);
  const [lastSelectedName, setLastSelectedName] = useState("");

  const [loading, setLoading] = useState({
    getFilters: false,
    chartData: false,
    counterMeasureReport: false,
    getQvForWave: false,
    clientComments: false
  });

  const handleLoading = (name, status) => {
    setLoading((pre) => ({
      ...pre,
      [name]: status,
    }));
  };

  useEffect(() => {
    if (projectId) {
      const fetchData = async () => {
        const res = await getProjectSection(projectId);
        if (res.status === 200) {
          setSection(res.data.sections[0]);
          setQuestionnaireJson(
            JSON.parse(res.data.sections[0].questionnaire.questionnaireJSON)
          );
        }
      };
      fetchData(projectId);
    }
    // setRegionModal(true);
  }, [projectId]);
  console.log("ques questionnaireJson", questionnaireJson);
  console.log("ques answerJson", answerJson);
  useEffect(() => {
    if (projectId) {
      const getDashboardLevelFilter = async (projectId) => {
        try {
          handleLoading("getFilters", true);
          const { status, data } = await getFiltersSetting(projectId);
          if (status === 200 && data?.data) {
            console.log("data?.data", data?.data);
            setLevelsDropdown(data?.data);
          }
          handleLoading("getFilters", false);
        } catch (error) {
          handleLoading("getFilters", false);

          // handleLoading("fetchLoading", false);
        }
      };
      getDashboardLevelFilter(projectId);
    }
  }, [projectId]);

  const handleGeneralDetailFilterDropdownChange = (
    event,
    dropdownName,
    lastDropdown
  ) => {
    debugger;
    const { value, id, name } = event.target;
    console.log("id", id, name);
    const find = lastDropdown?.find((cur) => cur?.id == value)?.name;
    if (find) {
      setLastSelectedName(find);
    }
    console.log("found", find);

    const res = removeLevelsAfterSelected(
      dropdownName,
      selectedGeneralDetailFilterValues
    );
    setSelectedGeneralDetailFilterValues((prevValues) => ({
      ...res,
      [dropdownName]: value,
    }));
  };

  // Function to remove key-value pairs after the selected level
  const removeLevelsAfterSelected = (selectedLevel, dropdownoptions) => {
    if (dropdownoptions[selectedLevel]) {
      const selectedLevelIndex =
        Object.keys(dropdownoptions).indexOf(selectedLevel);
      const keysToRemove = Object.keys(dropdownoptions).slice(
        selectedLevelIndex + 1
      );
      keysToRemove.forEach((key) => {
        delete dropdownoptions[key];
      });
    }
    return dropdownoptions;
  };

  useEffect(() => {
    console.log(
      "selectedGeneralDetailFilterValues",
      selectedGeneralDetailFilterValues
    );
    if (levelsDropdown?.dropDownFilters) {
      let dropDownFiltersLastLevel = Object.keys(
        levelsDropdown?.dropDownFilters
      )[Object.keys(levelsDropdown?.dropDownFilters).length - 1];
      let selectedGeneralDetailFilterValuesLastLevel = Object.keys(
        selectedGeneralDetailFilterValues
      )[Object.keys(selectedGeneralDetailFilterValues).length - 1];
      console.log(
        "first",
        dropDownFiltersLastLevel,
        "second",
        selectedGeneralDetailFilterValuesLastLevel
      );
      if (
        dropDownFiltersLastLevel === selectedGeneralDetailFilterValuesLastLevel
      ) {
        console.log("call api");

        getFilteredDocumentByLevels();
      }
    }
  }, [selectedGeneralDetailFilterValues]);

  const getFilteredDocumentByLevels = async () => {
    debugger;
    handleLoading("chartData", true);
    let payload = {
      projectId,
      ...selectedGeneralDetailFilterValues,
    };
    const { status, data } = await fetchFilteredDocumentByLevels(payload);

    console.log(
      "fetchFilteredDocumentByLevels",
      status,
      data,
      data[0]?.waveDataTable,
      "otherAttributes",
      data[0]?.otherAttributes[0]?.detail[0]?.value,
      "media urls",
      data[0]?.mediaLinks
    );

    // if(status === 200 && data[0]?.otherAttributes[0]?.detail[0]?.value){
    //   setAnswerJson(data[0]?.otherAttributes[0]?.detail[0]?.value)
    //   }else{
    //   setAnswerJson("")
    //   }

    // if(data[0]?.mediaLinks){
    //   setMediaUrl(data[0]?.mediaLinks);
    // }else{
    //   setMediaUrl([]);
    // }

    if (status === 200 && data.length) {
      let isQvAvailable = false;
      let isMediaLinksAvailable = false;

      data[0]?.otherAttributes.forEach((cur) => {
        // if(cur.title === "Answer Json"){
        //   setAnswerJson(cur?.detail[0]?.value)
        // }

        if (cur.title === "mediaLinks") {
          isMediaLinksAvailable = true;
          setMediaUrl(cur?.detail[0]?.value);
        }

        if (cur.title === "qv") {
          isQvAvailable = true;
          // setQV(cur?.detail[0]?.value);
          setQV(cur);
        }
      });

      if (!isMediaLinksAvailable) {
        setMediaUrl([]);
      }

      if (!isQvAvailable) {
        setQV([]);
      }

      debugger;

      //removing recordStatus = fail start
      let waveDetailFailLables = [];

      data[0]?.waveDetail?.forEach((cur) => {
        if(cur?.recordStatus === 'Pass'){
          waveDetailFailLables.push(cur?.label);
        }
      });

      let tempWaveDataTable = [...data[0]?.waveDataTable];

      tempWaveDataTable?.forEach((cur) => {
        let filter = cur?.waves?.filter((subcur) => waveDetailFailLables.includes(subcur?.waveLabel));
       cur.waves =  filter;
      });

      //removing recordStatus = fail ends


      setChartData(tempWaveDataTable);
      handleLoading("chartData", false);
    } else {
      setChartData([]);
      handleLoading("chartData", false);
    }
  };

  function beautify(str) {
    str = str.replaceAll("_", " ");
    str = str.replaceAll("&", " & ");
    return str;
  }

  const [chartType, setChartType] = useState("line");
  const [showCharGrid, setShowChartGrid] = useState(false);

  function changeChartRadio(e, name) {
    debugger;
    setChartType(name);
  }

  console.log("z", auth.userName);

  console.log(
    "last level",
    Object.values(selectedGeneralDetailFilterValues)[
      Object.values(selectedGeneralDetailFilterValues).length - 1
    ],
    "again="
  );

  const { toPDF, targetRef } = usePDF({
    filename: `${lastSelectedName}.pdf`,
    page: { margin: Margin.NONE },
  });

  // const changeClientCom = (value, mainObj, subObj, firstIndex) => {
  //   debugger;
  //  console.log("value", value, mainObj, subObj);
  //  let temp = {...qV};
  //  console.log("temp?.detail[firstIndex]?.value?.mainObj?.subObj", temp?.detail?.[firstIndex]?.value?.[mainObj]?.[subObj], "temp?.detail[firstIndex]", temp?.detail[firstIndex], "temp[detail][firstIndex][value][mainObj][subObj][clientComment]", temp["detail"][firstIndex]["value"][mainObj][subObj]["clientComment"]);

  // //  temp?.mainObj?.subObj?.clientComment = value;
  // // temp[mainObj][subObj]["clientComment"] = value;
  // // temp?.detail?.[firstIndex]?.value?.[mainObj]?.[subObj]["clientComment"] = value;
  // temp["detail"][firstIndex]["value"][mainObj][subObj]["clientComment"] = value;

  // setQV(temp);

  // console.log("temp", temp);

  // }

  useEffect(() => {
   console.log("qVvvvv", qV);
  }, [qV])

  const handleQvSave = () => {
 
  }

  const [WavesList, setWavesList] = useState([]);

  useEffect(() => {
      debugger
      if(projectId){
      fetchWaves(projectId);
      }
    }, [projectId]);

    const fetchWaves = async (projectId) => {
      const res = await fetchProjectWavesForDropDown(projectId);
      if (res.status === 200) {
     console.log("res", res, "res.data", res.data);
     setWavesList(res.data);
     }
    }



  return (
    <div>
      <div ref={targetRef}>
        {/* //--------layout = redirect user to /projects if guesUser is undefined, false, null */}
        {/* //------AnonymousSocialQuestionnaire = gives modal if api response is undefined from webClientApi */}
        {/* <Header /> */}

        <div id="ProjectDashboard">
          <div className="d-flex flex-row justify-content-between">
            <div className="">
              <img
                src={levelsDropdown?.logo || require("../../assets/logo.png")}
                width="100"
                alt=""
              />
            </div>
            <div className="heading-div text-center">
              <h5 className="text-capitalize heading-1">
                pak suzuki dealer assessment report ({" "}
                {chartData?.[0]?.waves[chartData?.[0]?.waves?.length - 1]
                  ?.date || " - "}{" "}
                ){" "}
              </h5>
              <h5 className="heading-2">NEW CAR SHOWROOM</h5>
            </div>

            <div className="">
              {/* <img src={require('../../assets/logo.png')} width="100" alt="" /> */}
            </div>
          </div>

          {/* <div className="d-flex flex-wrap justify-content-between mt-3">
            {loading.getFilters && "Loading..."}
            {levelsDropdown?.dropDownFilters
              ? Object.keys(levelsDropdown.dropDownFilters)?.map(
                  (dropdown, index) => {
                    if (index === 0) {
                      return (
                        <div key={index} className="mb-1">
                          <label htmlFor="">
                            {levelsDropdown?.levelsJSON?.[index]["name"]}
                          </label>
                          <select
                            style={{ height: "32px", width: "360px" }}
                            className="form-select form-control form-select-sm fontSize_12"
                            onChange={(e) =>
                              handleGeneralDetailFilterDropdownChange(
                                e,
                                dropdown
                              )
                            }
                            value={
                              selectedGeneralDetailFilterValues[dropdown] || ""
                            }
                            aria-label="Default select example"
                          >
                            <option selected>select option</option>
                            {levelsDropdown?.dropDownFilters[dropdown]?.map(
                              (option) => {
                                return (
                                  <>
                                    {console.log(
                                      "Premission",
                                      option?.Permission
                                    )}
                                    {(option?.Permission?.includes(
                                      auth?.userName
                                    ) ||
                                      option?.Permission?.includes(
                                        auth?.id?.toString()
                                      )) && (
                                      // logic
                                      <option
                                        key={option?.id}
                                        value={option?.id}
                                      >
                                        {option?.name}
                                      </option>
                                    )}
                                  </>
                                );
                              }
                            )}
                          </select>
                        </div>
                      );
                    } else {
                      const previousDropdownSelectedValue =
                        selectedGeneralDetailFilterValues[
                          Object.keys(levelsDropdown.dropDownFilters)[index - 1]
                        ];
                      return (
                        <div key={index} className="mb-1">
                          <label htmlFor="">
                            {levelsDropdown?.levelsJSON?.[index]["name"]}
                          </label>
                          <select
                            value={
                              selectedGeneralDetailFilterValues[dropdown] || ""
                            }
                            style={{ height: "32px", width: "360px" }}
                            onChange={(e) =>
                              handleGeneralDetailFilterDropdownChange(
                                e,
                                dropdown,
                                levelsDropdown?.dropDownFilters[dropdown][
                                  previousDropdownSelectedValue
                                ]
                              )
                            }
                            className="form-select form-control form-select-sm fontSize_12"
                            aria-label="Default select example"
                          >
                            <option selected>select option</option>
                            {console.log(
                              "previousDropdownSelectedValue",
                              levelsDropdown?.dropDownFilters[dropdown][
                                previousDropdownSelectedValue
                              ]
                            )}
                            <option key={-1} value={-1}>
                              All
                            </option>
                            {levelsDropdown?.dropDownFilters[dropdown][
                              previousDropdownSelectedValue
                            ]?.map((option) => {
                              return (
                                <option key={option?.id} value={option?.id}>
                                  {option?.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      );
                    }
                  }
                )
              : ""}
          </div> */}

          {/* {loading.chartData && (
                <div className="initail_Message">Loading...</div>
              )} */}


{/* {loading.chartData === false && chartData?.length > 0 ? ( */}

          <Box sx={{ width: "100%", marginTop: "30px" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Dashboard" style={{textTransform: 'none'}} {...a11yProps(0)} />
                <Tab label="Counter Measure Dashboard" style={{textTransform: 'none'}} {...a11yProps(1)} />
                <Tab label="Questionnaire" style={{textTransform: 'none'}} {...a11yProps(2)} />

              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>

{/* //first tab dropdowns start */}
<div className="d-flex flex-wrap justify-content-between mt-3">
            {loading.getFilters && "Loading..."}
            {levelsDropdown?.dropDownFilters
              ? Object.keys(levelsDropdown.dropDownFilters)?.map(
                  (dropdown, index) => {
                    if (index === 0) {
                      return (
                        <div key={index} className="mb-1">
                          <label htmlFor="">
                            {levelsDropdown?.levelsJSON?.[index]["name"]}
                          </label>
                          <select
                            style={{ height: "32px", width: "360px" }}
                            className="form-select form-control form-select-sm fontSize_12"
                            onChange={(e) =>
                              handleGeneralDetailFilterDropdownChange(
                                e,
                                dropdown
                              )
                            }
                            value={
                              selectedGeneralDetailFilterValues[dropdown] || ""
                            }
                            aria-label="Default select example"
                          >
                            <option selected>select option</option>
                            {levelsDropdown?.dropDownFilters[dropdown]?.map(
                              (option) => {
                                return (
                                  <>
                                    {console.log(
                                      "Premission",
                                      option?.Permission
                                    )}
                                    {(option?.Permission?.includes(
                                      auth?.userName
                                    ) ||
                                      option?.Permission?.includes(
                                        auth?.id?.toString()
                                      )) && (
                                      // logic
                                      <option
                                        key={option?.id}
                                        value={option?.id}
                                      >
                                        {option?.name}
                                      </option>
                                    )}
                                  </>
                                );
                              }
                            )}
                          </select>
                        </div>
                      );
                    } else {
                      const previousDropdownSelectedValue =
                        selectedGeneralDetailFilterValues[
                          Object.keys(levelsDropdown.dropDownFilters)[index - 1]
                        ];
                      return (
                        <div key={index} className="mb-1">
                          <label htmlFor="">
                            {levelsDropdown?.levelsJSON?.[index]["name"]}
                          </label>
                          <select
                            value={
                              selectedGeneralDetailFilterValues[dropdown] || ""
                            }
                            style={{ height: "32px", width: "360px" }}
                            onChange={(e) =>
                              handleGeneralDetailFilterDropdownChange(
                                e,
                                dropdown,
                                levelsDropdown?.dropDownFilters[dropdown][
                                  previousDropdownSelectedValue
                                ]
                              )
                            }
                            className="form-select form-control form-select-sm fontSize_12"
                            aria-label="Default select example"
                          >
                            <option selected>select option</option>
                            {console.log(
                              "previousDropdownSelectedValue",
                              levelsDropdown?.dropDownFilters[dropdown][
                                previousDropdownSelectedValue
                              ]
                            )}
                            {/* <option key={-1} value={-1} disabled={previousDropdownSelectedValue == -1 }>All</option> */}
                            <option key={-1} value={-1}>
                              All
                            </option>
                            {/* Render options based on the selected value of the previous dropdown */}
                            {levelsDropdown?.dropDownFilters[dropdown][
                              previousDropdownSelectedValue
                            ]?.map((option) => {
                              return (
                                <option key={option?.id} value={option?.id}>
                                  {option?.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      );
                    }
                  }
                )
              : ""}
          </div>
{/* //first tab dropdowns end */}


            {loading.chartData && (
                <div className="initail_Message">Loading...</div>
              )}

{loading.chartData === false && chartData?.length > 0 ? (
  <>
              {/* //first tab code satrt */}

                <>
                  {chartData?.length > 0 && (
                    <ChartSummary chartData={chartData} />
                  )}

                  <div className="d-flex align-items-baseline mt-3">
                    <div className="me-2" style={{ fontSize: "0.8rem" }}>
                      <b>Options:</b>
                    </div>
                    <div className="me-2">
                      <ManageVideo mediaUrl={mediaUrl} />
                    </div>
                    <div className="">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="line"
                          checked={chartType === "line"}
                          onChange={(e) => changeChartRadio(e, "line")}
                        />
                        <label class="form-check-label" for="flexRadioDefault1">
                          Line Chart
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="column"
                          checked={chartType === "column"}
                          onChange={(e) => changeChartRadio(e, "column")}
                        />
                        <label class="form-check-label" for="flexRadioDefault2">
                          Bar Chart
                        </label>
                      </div>
                    </div>
                    <div className="me-2">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                          checked={showCharGrid === true}
                          onChange={(e) => setShowChartGrid(!showCharGrid)}
                        />
                        <label class="form-check-label" for="flexCheckChecked">
                          Grid
                        </label>
                      </div>
                    </div>

                    <div className="me-2">
                      <a
                        onClick={toPDF}
                        className="btn-primary btn btn-sm px-1 py-0"
                        // href="/"
                      >
                        Export PDF
                      </a>
                    </div>
                  </div>
                </>

              {loading.chartData === false && chartData?.length > 0 && (
                <>
                  {chartData.map((cur) => (
                    <>
                      <ChartData
                        heading={cur.label}
                        optionDetail={cur.waves}
                        chartType={chartType}
                        showCharGrid={showCharGrid}
                      />
                    </>
                  ))}
                </>
              )}

              {loading.chartData === false && qV?.length !== 0 && (
                <Accordion className="mt-3">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Questionniare</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* <div className="text-end mb-3">
                    <button
            onClick={handleQvSave} 
            type="button"
            className="btn btn-primary px-4"
          >
            Save
          </button>
                    </div> */}
                    <table class="table questionare_Table">
                      <thead>
                        <tr>
                          <th className="border" scope="col">
                            Serial&nbsp;No.
                          </th>
                          <th className="border"scope="col">
                            Questions
                          </th>
                          {/* <th scope="col">Response</th> */}
                          <th className="border" scope="col">
                            Available&nbsp;Marks
                          </th>
                          <th className="border" scope="col">
                            Achived&nbsp;Marks
                          </th>
                          <th className="border" style={{width: '20%'}} scope="col">
                            Shopper&nbsp;Comments
                          </th>
                          {/* <th className="border" style={{width: '20%'}} scope="col">
                            Client&nbsp;Comments
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {console.log("qV1", qV, "qV?.detail", qV?.detail)}
                        {/* {Object.entries(qV)?.map((cur, index) => ( */}
                        {qV?.detail?.map((firstElement, firstIndex) => (
                          
                          <>
                        {console.log("qV2", qV, "firstElement", firstElement, firstIndex, qV?.detail?.length)}

                          {/* {firstElement?.label == "Wave-10-V2" &&  */}
                          {firstIndex === qV?.detail?.length - 1 &&
                          <>
                           {Object.entries(firstElement?.value)?.map((cur, index) => (
                         
                         //rendring start
                         <>
                        {console.log("qV3", qV, "firstElement", cur)}

                         <tr
                           className="border"
                           style={{
                             backgroundColor: "#36508D",
                             color: "white",
                           }}
                         >
                           {/* {console.log("cur[0]", cur[0], "cur[1]", cur[1], "Object.entries(qV)", Object.entries(cur[1]) )} */}

                           <th scope="row"></th>
                           <td>
                             <b>{cur[0]}</b>
                           </td>
                           {/* <td></td>  */}
                           <td></td>
                           <td></td>
                           <td></td>
                           <td></td>
                         </tr>

                         {Object.entries(cur[1]).map((sub, i) => (
                           <tr>
                             {/* {console.log("sub", sub, "sub[1].caption", sub[1].caption )} */}
                            {console.log("cur[0], sub[0]", cur[0], sub[0])}
                             <td className="border">{sub[1]?.serialNo}</td>
                             <td className="border">{sub[1]?.caption}</td>
                             {/* <td>{}</td> */}
                             <td className="border text-center">
                               {sub[1]?.maxValue}
                             </td>
                             <td className="border text-center">
                               {sub[1]?.acheivedValue}
                             </td>
                             <td className="border">{sub[1]?.comment}</td>
                             {/* <td className="border">
                               {sub[1]?.clientComment}
                             </td> */}
                           </tr>
                         ))}
                       </>
                        //  rendring ends

                           ))}

                          </>
                          }

                          </>
                        ))}

                   {/* {console.log("qV", qV, "Object.entries(qV)", Object.entries(qV))} */}
                        {/* original code start */}
                        {/* {Object.entries(qV)?.map((cur, index) => (
                          <>
                          
                            <tr
                              className="border"
                              style={{
                                backgroundColor: "#36508D",
                                color: "white",
                              }}
                            >

                              <th scope="row"></th>
                              <td>
                                <b>{cur[0]}</b>
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>

                            {Object.entries(cur[1]).map((sub, i) => (
                              <tr>
                               {console.log("cur[0], sub[0]", cur[0], sub[0])}
                                <td className="border">{sub[1]?.serialNo}</td>
                                <td className="border">{sub[1]?.caption}</td>
                                <td className="border text-center">
                                  {sub[1]?.maxValue}
                                </td>
                                <td className="border text-center">
                                  {sub[1]?.acheivedValue}
                                </td>
                                <td className="border">{sub[1]?.comment}</td>
                                <td className="border">
                                  <input type="text" className="form-control" value={sub[1]?.clientComment || null} onChange={(e) => changeClientCom(e.target.value, cur[0], sub[0])}  />
                                </td>
                              </tr>
                            ))}
                          </>
                        ))} */}
                        {/* original code ends */}
                        
                      </tbody>
                    </table>
                  </AccordionDetails>
                </Accordion>
              )}
</>
              ) : chartData === null && loading.chartData === false ? (
                <div className="initail_Message">
                  Waiting For The User Action
                </div>
              ) : (
                <div className="initail_Message">No Data</div>
              )}

              {/* //first tab code ends */}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>

              <QuestionMR levelsDropdown={levelsDropdown} auth={auth} selectedWave2={selectedWave2} setSelectedWave2={setSelectedWave2} counterMeasureReport={counterMeasureReport} setCounterMeasureReport={setCounterMeasureReport} selectedGeneralDetailFilterValues2={selectedGeneralDetailFilterValues2} setSelectedGeneralDetailFilterValues2={setSelectedGeneralDetailFilterValues2} WavesList={WavesList} selectedGeneralDetailFilterValues={selectedGeneralDetailFilterValues} handleLoading={handleLoading} loading={loading} qV={qV} setQV={setQV} projectId={projectId}/>
          
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
              <QuestionInputTab levelsDropdown={levelsDropdown} auth={auth} selectedWave3={selectedWave3} setSelectedWave3={setSelectedWave3} selectedGeneralDetailFilterValues3={selectedGeneralDetailFilterValues3} setSelectedGeneralDetailFilterValues3={setSelectedGeneralDetailFilterValues3} WavesList={WavesList} handleLoading={handleLoading} loading={loading} qV={qV} setQV={setQV} projectId={projectId} />
            </CustomTabPanel>

          </Box>

          {/* ) : chartData === null && loading.chartData === false ? (
                <div className="initail_Message">
                  Waiting For The User Action
                </div>
              ) : (
                <div className="initail_Message">No Data</div>
              )} */}

        </div>
      </div>
    </div>
  );
}

export default ProjectDashboard;
