import React, { useEffect, useState } from 'react'
import ProjectDashboard from '../../pages/ProjectDashboard/index'
import Questionnaire from '../../pages/Questionnaire'
import { fetchWebClientRunByOption } from '../../apis'
import { useParams } from "react-router-dom";


function WebClientSetting() {
    const [runByOption, setRunByOption] = useState("");
    const { id } = useParams();
    useEffect(() => {
        if(id){
            fetchRunByOption(id);
        }
    }, [id])

    const fetchRunByOption = async (projectId) => {
        const response = await fetchWebClientRunByOption(projectId);
        console.log("fetchWebClientRunByOption", response, "response.data?.webClientRunByOption", response.data?.data?.webClientRunByOption);
        if (response.status === 200) {   
            setRunByOption(response.data?.data?.webClientRunByOption)
        }
    }



  return (
    <div className='pb-4'>
    
    {runByOption === "dashboard" && <ProjectDashboard/> }
    {runByOption === "survey" && <Questionnaire/>}
    </div>
  )
}

export default WebClientSetting
