import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import AnonymousHeader from "../components/AnonymousHeader";
import RegionsModal from "../components/RegionsModal";
import "bootstrap/dist/js/bootstrap.min";
import { GlobalContext } from "../global_context";

const AnonymousLayout = () => {
  const { regionModalShow } = useContext(GlobalContext);
  return (
    <div
      style={{
        backgroundColor: "#fafafa",
        position: "relative",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <AnonymousHeader />
      <div className="mb-5">
        <Outlet />
      </div>
      <Footer />
      <RegionsModal show={regionModalShow} />
    </div>
  );
};

export default AnonymousLayout;
