import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userLogin } from "../../apis";
import { GlobalContext } from "../../global_context";
import sideImg from "../../assets/Mask Group 477.jpg";
import resposiveImage from "../../assets/modern-buildings.jpg";
import logo from "../../assets/biCruxcoluredLogo.svg";
import { parseJwt } from "../../utils";
import { BICRUX_URL, PROJECT_LIST_ROUTE } from "../../constants";
const Login = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("");

  const { login } = useContext(GlobalContext);

  useEffect(() => {
    console.log("const { login } = useContext(GlobalContext);", login);
  }, [login]);

  const handleLogin = async (e) => {
    try {
      e.preventDefault();
      if (!userName || !password) {
        return;
      } else {
        setLoading(true);
        const body = {
          userName: userName,
          password: password,
        };
        const res = await userLogin(body);
        console.log("userLogin", res);
        if (res.status === 200) {
          if (res.data.token) {
            setLoading(false);
            localStorage.setItem("auth-token", res.data.token);
            login(parseJwt(res.data.token));
            navigate(PROJECT_LIST_ROUTE);
          }
        } else {
          setErr("Invalid username or password.");
          setLoading(false);
        }
      }
    } catch (error) {
      setErr("Invalid username or password.");
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      {/* <Header /> */}
      <div className="login-wrapper p-5 ">
        {/* <div className="logo">
          <img
            alt="company-logo"
            style={{ width: "151px", height: "29px" }}
            src={logo}
          />
        </div> */}
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="container p-0  shadow row login-form-main">
            <div className="col-md-6 px-0 login-side-image-wrapper-top  col-sm-12">
              <img className="login-side-image" src={resposiveImage} />
            </div>
            <div className="py-4 px-5 col-md-6 col-sm-12 ml-2">
              <div style={{ fontSize: "16px" }}>
                <img
                  alt="small-company-logo"
                  style={{
                    width: "75px",
                    height: "34px",
                    position: "relative",
                    top: "-3px",
                  }}
                  src={logo}
                />
                <span className="text-muted mx-2">|</span>
                <span className="text-muted">Survey Client</span>
              </div>

              <h3 className="login-form-heading fw-bold mt-5 mb-0">
                Real Time Insights
                <br />{" "}
                {/* <span className="fw-bold" style={{ color: "#FC760D" }}>
                  Markit
                </span> */}
                <span>in a Flick!</span>
              </h3>
              <p className="px-1 text-muted mt-4 slogon-title">
                Our clients are our biggest treasure
              </p>
              <form onSubmit={handleLogin}>
                <div className="login-form mt-5">
                  <div className="form-group my-3 shadow-custom">
                    <input
                      placeholder="Email"
                      required
                      type="text"
                      className="form-control  border-0 p-2"
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </div>
                  <div className="form-group mt-3 shadow-custom">
                    <input
                      placeholder="Password"
                      required
                      type="password"
                      className="form-control border-0 p-2"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <span className="d-block my-2 text-danger error-message">
                    {err}
                  </span>
                  <div className="form-group mb-3 mt-2">
                    <button
                      type="submit"
                      disabled={loading}
                      className="btn border-0 rounded-0 btn-primary w-100"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </form>

              <br />
              <br />

              <p className="mt-2 mb-0 text-primary footer-branding-text">
                <span className="fw-bold">Powered by</span>{" "}
                <a
                  className="text-decoration-none"
                  href={BICRUX_URL}
                  target="_blank"
                >
                  <span className="text-muted fw-bold">BICRUX</span>
                </a>
              </p>
            </div>
            <div className="col-md-6 col-sm-12 px-0 login-side-image-wrapper-side">
              <img
                className="login-side-image d-none d-sm-block"
                src={sideImg}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
