import React from "react";
import Button from "@mui/material/Button";
import { PROJECT_LIST_ROUTE } from "../../constants";
import { Link } from "react-router-dom";
const SurveyThanks = ({ url, date, message, buttonText, backToHome }) => {
  return (
    <div
      style={{ height: "85vh" }}
      className="text-center d-flex flex-column justify-content-center flex-column align-items-center"
    >
      <div className="py-2">
        <b>{message}</b>
      </div>
      {date ? (
        <div className="py-2">
          <b>Expired Time:</b> <span>{date}</span>
        </div>
      ) : (
        ""
      )}

      <div className="py-2">
        <a href={url} target="_blank">
          {url}
        </a>
      </div>
      {backToHome ? (
        <div className="py-2">
          <Button
            variant="contained"
            style={{
              backgroundColor: "rgb(6, 27, 127) !important",
              fontSize: "12px",
              textTransform: "capitalize",
              fontFamily: "Montserrat",
            }}
          >
            <Link
              className="text-decoration-none text-white"
              to={PROJECT_LIST_ROUTE}
            >
              {" "}
              {buttonText}
            </Link>
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SurveyThanks;
