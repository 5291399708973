import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import { getAllProjectsByUserId } from "../../apis";
import { GlobalContext } from "../../global_context";
import CardComponent from "../../components/ProjectDetails/card";
import CardSkeleton from "../../components/skeletons/cardSkeleton";
import Alert from "@mui/material/Alert";
import ProjectDashboard from "../ProjectDashboard";
import ManageVideo from "../../components/ManageVideo/ManageVideo";
const Projects = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { auth, region, setRegion } = useContext(GlobalContext);

  useEffect(() => {
    fetchProjects();
  }, []);


  const fetchProjects = async () => {
    setLoading(true);
    const res = await getAllProjectsByUserId(auth.id);
    if (res.status == 200) {
      setData(res.data);
      setLoading(false);
    }
  };
  const resetSelectedRegion = () => {
    setRegion("");
  };

  return (
    <>
      <Header />
      {region ? (
        <Alert severity="info">
          <span
            style={{ color: "#001fff", cursor: "pointer" }}
            className="px-1 text-decoration-underline"
            onClick={() => resetSelectedRegion()}
          >
            click here
          </span>{" "}
          to clear the selected Region.
        </Alert>
      ) : (
        ""
      )}

      <div className="d-flex justify-content-center py-5">
        <div className="row container">
          {loading
            ? [0, 1, 2]?.map((el, ind) => (
                <div key={ind} className="col-md-4 my-3">
                  <CardSkeleton />
                </div>
              ))
            : data.map((data, ind) => (
                <div className="col-md-4 my-3" key={ind}>
                  {console.log("data", data)}
                  <CardComponent data={data} />
                </div>
              ))}
        </div>
      </div>


     {/* <ProjectDashboard/> */}

     {/* <ManageVideo/> */}
      
    </>
  );
};

export default Projects;
