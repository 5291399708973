import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProjectRegions } from "../../apis";
import { GlobalContext } from "../../global_context";
import Select from "react-select";
import { Modal } from "react-bootstrap";

const RegionsModal = (props) => {
  const { id } = useParams();
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [loading, setLoading] = useState(true);
  const { auth, setRegion, setRegionModal } = useContext(GlobalContext);

  useEffect(() => {
    if (!regions?.length) {
      const fetchData = async () => {
        // const res = await getProjectRegions({ userId: auth?.id, projectId: id });
        const res = await getProjectRegions({ projectId: id });
        if (res.status === 200) {
          setRegions(res.data?.regions);
        }
      };
      fetchData();
    }
  }, [id, auth, regions]);

  const options = regions?.map((reg) => ({
    value: reg,
    label: reg?.subArea?.name,
  }));

  const regionSelectHandle = () => {
    if (selectedRegion) {
      setRegion(selectedRegion);
      setRegionModal(false);
    }
  };
  const selectedRegionHanlde = (e) => {
    setSelectedRegion(e);
    setLoading(false);
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-primary">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-light"
          style={{ fontSize: "14px" }}
        >
          Select Region
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Select
          className="w-100"
          options={options}
          onChange={(e) => {
            selectedRegionHanlde(e.value);
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        {/* <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            setRegionModal(false);
          }}
        >
          Close
        </button> */}
        <button
          disabled={loading}
          onClick={() => regionSelectHandle()}
          type="button"
          className="btn btn-primary"
        >
          Select
        </button>
      </Modal.Footer>
    </Modal>
    // <div
    //   className="modal fade"
    //   id="staticBackdrop"
    //   data-bs-backdrop="static"
    //   data-bs-keyboard="false"
    //   tabIndex="-1"
    //   aria-labelledby="staticBackdropLabel"
    //   aria-hidden="true"
    // >
    //   <div className="modal-dialog modal-dialog-centered">
    //     <div className="modal-content">
    //       <div className="modal-header bg-primary">
    //         <h6 className="modal-title text-white" id="staticBackdropLabel">
    //           Select Region
    //         </h6>
    //         <button
    //           type="button"
    //           className="btn-close"
    //           data-bs-dismiss="modal"
    //           aria-label="Close"
    //         ></button>
    //       </div>
    //       <div className="modal-body">
    //         <div className="d-flex">
    //           <Select className="w-100" options={options} onChange={(e)=>{selectedRegionHanlde(e.value)}}/>
    //         </div>

    //       </div>
    //       <div className="modal-footer">
    //         <button
    //           type="button"
    //           className="btn btn-secondary"
    //           data-bs-dismiss="modal"
    //         >
    //           Close
    //         </button>
    //         <button disabled={loading} onClick={()=>regionSelectHandle()} type="button" className="btn btn-primary">
    //           Select
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default RegionsModal;
