import axios from "axios";

export const parseJwt = (token) => {
  var base64Url = token?.split(".")[1];
  var base64 = base64Url?.replace(/-/g, "+")?.replace(/_/g, "/");
  if (base64) {
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        ?.split("")
        ?.map(function (c) {
          return "%" + ("00" + c?.charCodeAt(0)?.toString(16))?.slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } else {
    return null;
  }
};

export const getDateTime = () => {
  var date = new Date();
  var current_date =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  var current_time =
    date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  var date_time = current_date + " " + current_time;
  return date_time;
};

export const formatAMPM = (date) => {
  var current_date =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = current_date + " " + hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const isDateInFuture = (date) => {
  let result = date > new Date();
  console.log("result", result);
  return date > new Date();
};

function getKeys(choices, key, answers, question, key1) {
  let arr = [];
  // var english = /^[A-Za-z0-9]*$/;
  // var english = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g
  choices &&
    choices.forEach((item, index) => {
      if (item.value === "other") {
        // let temp=key+'_'+'other'
        let temp = key;
        if (key1) {
          temp = temp + "_" + key1 + "_other";
        } else {
          // if(question.getType()==='checkbox'){
          //     temp=temp+'_other'
          // }
          temp = temp + "_other";
        }
        if (answers?.includes("other")) {
          arr.push({
            [temp]: {
              text: item.locOwner.comment,
              value: item.locOwner.comment,
            },
          });
        } else {
          if (question.getType() === "checkbox") {
            arr.push({
              [temp]: {
                text: "",
                value: "",
              },
            });
          } else if (question.getType() === "imagepicker") {
            arr.push({
              [temp]: {
                text: "",
                value: "",
              },
            });
          }
        }
      } else {
        // let temp=key+'_'+item.locText.text
        let temp = key;
        if (key1) {
          if (question.getType() === "checkbox") {
            temp = temp + "_" + key1 + "_" + (index + 1);
          } else if (question.getType() === "ranking") {
            temp = temp + "_" + key1 + "_" + (index + 1);
          } else if (question.getType() === "imagepicker") {
            temp = temp + "_" + key1 + "_" + (index + 1);
          } else {
            temp = temp + "_" + key1;
          }
        } else {
          if (question.getType() === "checkbox") {
            temp = temp + "_" + (index + 1);
          } else if (question.getType() === "ranking") {
            temp = temp + "_" + (index + 1);
          } else if (question.getType() === "imagepicker") {
            temp = temp + "_" + (index + 1);
          }
        }
        temp = temp.replace(/[&\/\\#,+()$~%.'":*?<>{}\s]/g, "_");
        temp = temp.replace(/_+/g, "_");
        temp = temp.replace(/_+$/, "");
        // let temp2={}
        if (answers?.includes(item.value)) {
          // console.log(answers, item, "answers");
          if (question.getType() === "ranking") {
            var currentItem = choices.find((x) => x.value === answers[index]);
            arr.push({
              [temp]: {
                text:
                  item.locText.text === ""
                    ? item.locText.textOrHtml
                    : currentItem.locText.text,
                value: currentItem.value || "",
              },
            });
          } else {
            arr.push({
              [temp]: {
                text:
                  item.locText.text === ""
                    ? item.locText.textOrHtml
                    : item.locText.text,
                value: item.value || "",
              },
            });
          }
        } else {
          if (question.getType() === "checkbox") {
            arr.push({
              [temp]: {
                text: "",
                value: "",
              },
            });
          } else if (question.getType() === "imagepicker") {
            arr.push({
              [temp]: {
                text: "",
                value: "",
              },
            });
          } else if (question.getType() === "ranking") {
            arr.push({
              [temp]: {
                text: "",
                value: "",
              },
            });
          }
          // else if(question.getType()==='radiogroup' || question.getType()==='dropdown'){
          //     debugger;
          //      let obj = arr[0];
          //     if(obj!== undefined ){
          //         console.log(Object.keys(obj));
          //         if(!Object.keys(obj).includes(temp))
          //         {
          //     arr.push({
          //         [temp]:{
          //             text:'',
          //             value:''
          //         }
          //     })
          //         }
          // }
          // else
          // {
          //     arr.push({
          //         [temp]:{
          //             text:'',
          //             value:''
          //         }
          //     })
          // }
          // }
        }
        // arr.push(temp2)
      }
    });
  // console.log(question.getType());
  if (question.getType() !== "checkbox" && answers?.includes("other")) {
    if (key1) {
      arr.push({
        [`${key}_${key1}`]: {
          text: "",
          value: "",
        },
      });
    } else {
      arr.push({
        [`${key}`]: {
          text: "",
          value: "",
        },
      });
    }
  }
  if (question.getType() !== "checkbox" && !answers?.includes("other")) {
    if (question.hasOther) {
      if (key1) {
        arr.push({
          [`${key}_${key1}_other`]: {
            text: "",
            value: "",
          },
        });
      } else {
        arr.push({
          [`${key}_other`]: {
            text: "",
            value: "",
          },
        });
      }
    }
  }
  // console.log("arr", arr);
  return arr;
}
function getKeysForMatrix(choices, key, answers, question) {
  let arr = [];

  question.cells.rows.forEach((row, rowIndex) => {
    question.cells.columns.forEach((column, columnIndex) => {
      let temp = key + "_" + row.value;
      temp = temp.replace(/[&\/\\#,+()$~%.'":*?<>{}\s]/g, "_");
      temp = temp.replace(/_+/g, "_");
      temp = temp.replace(/_+$/, "");
      if (answers?.[row.value] === column.value) {
        arr.push({
          [temp]: {
            rowText: row.locText.text,
            rowValue: row.value || "",
            text: column.locText.text,
            value: column.value || "",
          },
        });
      }
    });
  });
  return arr;
}

function getKeysForMultiText(elements, key, answers, question) {
  // console.log("Multi text");
  // console.log(elements, key, answers, question);
  let arr = [];
  elements.forEach((item, index) => {
    let temp = key + "_" + (index + 1);
    temp = temp.replace(/[&\/\\#,+()$~%.'":*?<>{}\s]/g, "_");
    temp = temp.replace(/_+/g, "_");
    temp = temp.replace(/_+$/, "");
    arr.push({
      [temp]: {
        text: item.value,
        value: item.value,
      },
    });
  });
  // console.log(arr, "multitext");
  return arr;
}
function getKeysForMatrixDynamic(elements, key, answers, question) {
  let arr = [];
  elements.forEach((row, rowIndex) => {
    row.cells.forEach((cell, cellIndex) => {
      let temp = key + "_" + (rowIndex + 1) + "_" + (cellIndex + 1);
      temp = temp.replace(/[&\/\\#,+()$~%.'":*?<>{}\s]/g, "_");
      temp = temp.replace(/_+/g, "_");
      temp = temp.replace(/_+$/, "");
      if (
        cell.question.getType() === "text" ||
        cell.question.getType() === "comment" ||
        cell.question.getType() === "rating" ||
        cell.question.getType() === "boolean"
      ) {
        arr.push({
          [temp]: {
            text: cell.value,
            value: cell.value,
          },
        });
      } else if (cell.question.getType() === "checkbox") {
        let choicesKey = getKeys(
          cell.question.enabledChoices,
          key + `_` + (rowIndex + 1),
          answers[rowIndex][cell.question.name],
          cell.question,
          cellIndex + 1
        );
        choicesKey.forEach((item) => {
          arr.push(item);
        });
      } else if (
        cell.question.getType() === "radiogroup" ||
        cell.question.getType() === "dropdown"
      ) {
        if (answers.includes(rowIndex)) {
          let choicesKey = getKeys(
            cell.question.enabledChoices,
            key + `_` + (rowIndex + 1),
            [answers[rowIndex][cell.question.name]],
            cell.question,
            cellIndex + 1
          );
          choicesKey.forEach((item) => {
            arr.push(item);
          });
        }
      }
    });
  });
  return arr;
}

export const getAnswerResult = (survey, times) => {
  // Updated Answer Result Code Block - Haris
  const surveyData = survey.data;
  var data = [];
  var finalResults = [];

  // update code for parser start here 3/14/2022

  for (var key in survey.questionHashes.names) {
    var question = survey.getQuestionByValueName(key);
    let choicesTemp = [];
    let choicesKey = [];
    // console.log(question, survey.data[key], "536");
    if (question) {
      // choicesTemp=findChoices(question.enabledChoices,question)
      if (
        question.getType() === "microphone" ||
        question.getType() === "file"
      ) {
        // console.log("microphone or audio", question.getType());
        // console.log("question object", question, survey.data[key], key);
      } else {
        if (question.getType() === "matrix") {
          //   console.log("matrix", question.getType(), survey.data[key]);
          if (question.columns) {
            choicesKey = getKeysForMatrix(
              question.columns,
              key,
              survey.data[key],
              question
            );
          }
        } else if (question.getType() === "multipletext") {
          //   console.log("multipletext", question.getType(), survey.data[key]);

          choicesKey = getKeysForMultiText(
            question.items,
            key,
            survey.data[key],
            question
          );
        } else if (question.getType() === "matrixdynamic") {
          //   console.log("matrixdynamic", question.getType(), survey.data[key]);
          if (question.generatedVisibleRows != null) {
            choicesKey = getKeysForMatrixDynamic(
              question.generatedVisibleRows,
              key,
              survey.data[key],
              question
            );
          }
        } else if (question.getType() === "matrixdropdown") {
          //   console.log("matrixdropdown", question.getType(), survey.data[key]);
          if (question.generatedVisibleRows != null) {
            choicesKey = getKeysForMatrixDropdown(
              question.generatedVisibleRows,
              key,
              survey.data[key],
              question
            );
          }
        } //|| question.getType()==='dropdown' || question.getType()==='radiogroup'
        else if (
          question.getType() === "checkbox" ||
          question.getType() === "ranking" ||
          (question.getType() === "imagepicker" && question.multiSelect)
        ) {
          //   console.log("multiOptions", question.getType(), survey.data[key]);
          //   console.log("Enabledchoices", question.enabledChoices);
          //   console.log("choices", question.choices);
          choicesKey = getKeys(
            question.choices,
            key,
            survey.data[key],
            question
          );
        }
      }
      if (!question) continue;

      var questionResult = {
        type: question.getType(),
      };
      let temp = { ...questionResult };
      if (question.isOtherSelected) {
        if (
          question.getType() === "matrix" ||
          question.getType() === "multipletext" ||
          question.getType() === "matrixdropdown" ||
          question.getType() === "matrixdynamic"
        ) {
          temp["result"] = [...choicesKey];
          //   console.log("result", temp["result"]);
        } else if (question.getType() === "checkbox") {
          temp["result"] = [...choicesKey];
          //   console.log("dsadas", survey.data[question.name + "-Comment"]);
          temp.result.push({
            [`${question.name}_other`]: {
              text: survey.data[question.name + "-Comment"],
              value: survey.data[question.name + "-Comment"],
            },
          });
        } else if (
          question.getType() === "dropdown" ||
          question.getType() === "radiogroup" ||
          (question.getType() === "imagepicker" && !question.multiSelect)
        ) {
          temp["result"] = [
            {
              [`${key}_other`]: {
                text: question.displayValue,
                value: question.displayValue,
              },
            },
          ];
        } else if (
          question.getType() === "text" ||
          question.getType() === "rating" ||
          question.getType() === "comment" ||
          question.getType() === "boolean"
        ) {
          // temp['result']=[...choicesKey]
          temp["result"] = [
            {
              [`${key}_other`]: {
                text: question.displayValue,
                value: question.displayValue,
              },
            },
          ];
        }
      } else {
        //||
        if (
          question.getType() === "checkbox" ||
          question.getType() === "matrix" ||
          question.getType() === "multipletext" ||
          question.getType() === "matrixdropdown" ||
          question.getType() === "matrixdynamic" ||
          question.getType() === "ranking" ||
          (question.getType() === "imagepicker" && question.multiSelect)
        ) {
          temp["result"] = [...choicesKey];
          if (temp["result"].length === 0) {
            temp["result"] = [
              {
                [`${key}`]: {
                  text: question.displayValue,
                  value: question.displayValue,
                },
              },
            ];
          }
        } else if (
          question.getType() === "dropdown" ||
          question.getType() === "radiogroup" ||
          (question.getType() === "imagepicker" && !question.multiSelect)
        ) {
          temp["result"] = [...choicesKey];
          if (temp["result"].length === 0) {
            temp["result"] = [
              {
                [`${key}`]: {
                  text: question.displayValue,
                  value: survey.data[key] || "",
                },
              },
            ];
          }
        } else if (
          question.getType() === "text" ||
          question.getType() === "rating" ||
          question.getType() === "comment" ||
          question.getType() === "boolean" ||
          question.getType() === "microphone" ||
          question.getType() === "expression"
        ) {
          // temp['result']=[...choicesKey]
          temp["result"] = [
            {
              [key]: {
                text: question.displayValue,
                value: survey.data[key] || "",
              },
            },
          ];
        } else if (question.getType() === "file") {
          temp["result"] = survey.data[key]?.map((item, index) => {
            return {
              [`${key}_${index + 1}`]: {
                text: item.name,
                value: item.name,
                type: item.type,
              },
            };
          });
        }
      }
      questionResult = temp;
      if (questionResult.type != "html") {
        data.push(questionResult);
      }
    }
  }

  for (let i in data) {
    finalResults.push({ ...data[i], time: times[i] });
  }

  return finalResults;
};

function getKeysForMatrixDropdown(elements, key, answers, question) {
  let arr = [];
  elements.forEach((row, rowIndex) => {
    row.cells.forEach((cell, cellIndex) => {
      let temp = key + "_" + (rowIndex + 1) + "_" + (cellIndex + 1);
      temp = temp.replace(/[&\/\\#,+()$~%.'":*?<>{}\s]/g, "_");
      temp = temp.replace(/_+/g, "_");
      temp = temp.replace(/_+$/, "");
      if (
        cell.question.getType() === "text" ||
        cell.question.getType() === "comment" ||
        cell.question.getType() === "rating" ||
        cell.question.getType() === "boolean"
      ) {
        arr.push({
          [temp]: {
            text: cell.value,
            value: cell.value,
          },
        });
      } else if (cell.question.getType() === "checkbox") {
        //   console.log(question, "Error Question checkbox");
        //   console.log(cell.question.getType(), "Matrix Question Type");
        //   console.log(cell.question.name, "Matrix Question Name");
        //   console.log(typeof answers, "type of Anwser");
        if (typeof answers?.[row.name] !== "undefined") {
          // console.log(cell.question, "Question Matrix Dropdown");
          let choicesKey = getKeys(
            cell.question.enabledChoices,
            key + `_` + (rowIndex + 1),
            answers[row.name][cell.question.name],
            cell.question,
            cellIndex + 1
          );
          choicesKey.forEach((item) => {
            arr.push(item);
          });
        }
      } else if (
        cell.question.getType() === "radiogroup" ||
        cell.question.getType() === "dropdown"
      ) {
        //   console.log(question, "Error Question radiogroup DropDown");
        //   console.log(cell.question.getType(), "Matrix Question Type");
        //   console.log(cell.question.name, "Matrix Question Name");
        //   console.log(typeof answers, "type of Anwser");
        if (typeof answers?.[row.name] !== "undefined") {
          let choicesKey = getKeys(
            cell.question.enabledChoices,
            key + `_` + (rowIndex + 1),
            [answers[row.name][cell.question.name]],
            cell.question,
            cellIndex + 1
          );
          choicesKey.forEach((item) => {
            arr.push(item);
          });
        }
      }
    });
  });
  return arr;
}

export const isOnline = async () => {
  try {
    axios.get("https://www.google.com/").then((res) => {
      return true;
    });
  } catch (err) {
    return false;
  }
};
