import React from "react";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Tooltip } from "@mui/material";

const TextDirectionView = ({ alignment, setAlignment }) => {
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const children = [
    <ToggleButton
      className={`text-white p-1 ${alignment === "left" ? "border" : ""}`}
      value="left"
      key="left"
    >
      <FormatAlignLeftIcon />
    </ToggleButton>,

    <ToggleButton
      className={`text-white p-1 ${alignment === "right" ? "border" : ""}`}
      value="right"
      key="right"
    >
      <FormatAlignRightIcon />
    </ToggleButton>,
  ];

  const control = {
    value: alignment,
    onChange: handleChange,
    exclusive: true,
  };

  return (
    <div className={`d-flex pe-2`}>
      {/* <span
        style={{ fontWeight: "500" }}
        className={`font-size-15px pt-2 pe-2`}
      >
        Direction:{" "}
      </span> */}
      <Tooltip title="Text Direction">
        <Stack spacing={2} alignItems="center">
          <ToggleButtonGroup size="small" {...control} aria-label="Small sizes">
            {children}
          </ToggleButtonGroup>
        </Stack>
      </Tooltip>
    </div>
  );
};

export default TextDirectionView;
