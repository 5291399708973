import React from "react";
import thumbIcon from "../../assets/thumb-icon.png";
import Button from "@mui/material/Button";
const SurveyThanks = ({
  message,
  date,
  projectName,
  url,
  id,
  takeSuveyAgain,
}) => {
  return (
    <div
      style={{ height: "85vh" }}
      className="text-center d-flex flex-column justify-content-center flex-column align-items-center"
    >
      <div className="py-2">
        <img
          style={{ height: "40px", width: "40px" }}
          src={thumbIcon}
          alt="thanks-icon"
        />
      </div>
      <div className="py-2">
        <b>{message}</b>
      </div>
      <div className="py-2">
        <b>Date:</b>{" "}
        <span>
          {date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate()}
        </span>
      </div>
      <div className="py-2">
        <span>{projectName}</span>
      </div>
      <div className="py-2">
        <a href={url} target="_blank">
          {url}
        </a>
      </div>
      {takeSuveyAgain ? (
        <div className="py-2">
          <Button
            variant="contained"
            style={{
              backgroundColor: "rgb(6, 27, 127) !important",
              fontSize: "12px",
              textTransform: "capitalize",
              fontFamily: "Montserrat",
            }}
          >
            <a className="text-decoration-none text-white" href={`/${id}?u=g`}>
              {" "}
              take survey again
            </a>
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SurveyThanks;
