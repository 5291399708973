import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  SyncWebClientData,
  fetchWebClientSettingsByURL,
  gmailSignUp,
  fetchProjectSectionByURL,
  getProjectRegions,
  fetchClassificationOneOffSetting,
} from "../../apis";
import { toast } from "react-toastify";
import Countdown from "react-countdown";
import SurveyCreatorComponent from "../../components/Survey";
import loader from "../../assets/Markit-Survey-Logo1.gif";
import { GlobalContext } from "../../global_context";
import { formatAMPM, getDateTime, isDateInFuture, parseJwt } from "../../utils";
import uuid from "react-uuid";
import styles from "./questionnaire.module.css";
import clsx from "clsx";
import SocialLoginModal from "../../components/SocialLoginModal";
import {
  ANONYMOUS_USER,
  SOCIAL_LOIGN,
  GOOGLE_CLIENT_ID,
  ERROR_TEXT,
  LOGIN_PAGE_ROUTE,
  QUESTIONNAIRE_RESULT,
  BICRUX_URL,
  RESPONSE_SAVE_MESSAGE,
} from "../../constants";
import { gapi } from "gapi-script";
import AlertModal from "../../components/AlertModal";
import SurveyThanks from "../../components/SurveyThanks";
import SurveyExpire from "../../components/SurveyExpire";
const LOADING_STATE = {
  initialLoading: false,
};
const userAuthId = 742;
function AnonymousSocialQuestionnaire() {
  const [questionnaireJson, setQuestionnaireJson] = useState([]);
  const [section, setSection] = useState([]);
  const [location, setLocation] = useState({});
  const [closeQuestionnaire, setCloseQuestionnaire] = useState(false);
  const [userAuth, setUserAuth] = useState();
  const [surveyStartTime, setSurveyStartTime] = useState();

  const [openSocialLoginModal, setOpenSocialLoginModal] = useState(false);
  const [openLoading, setOpenLoading] = useState({ ...LOADING_STATE });
  const [regions, setRegions] = useState();
  const [oneOffSetting, setOneOffSetting] = useState();
  const { setLoading } = useContext(GlobalContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [webClientSetting, setWebClientSetting] = useState();
  const [alertModal, setAlertModal] = useState({
    showModel: false,
    message: "",
    title: "",
    buttonText: "",
  });
  const [surveyExpired, setSurveyExpired] = useState({
    showComponent: false,
    message: "",
    buttonText: "",
    backToHome: false,
    url: "",
    date: "",
  });
  const { initialLoading } = openLoading;

  useEffect(() => {
    let _data = JSON.parse(localStorage.getItem("local_d"));
    if (_data != null) {
      let online = window.navigator.onLine;
      if (online) {
        try {
          SyncWebClientData(_data).then((res) => {
            localStorage.removeItem("local_d");
          });
        } catch (error) {}
      }
    }
  }, []);
  useEffect(() => {
    localStorage.removeItem(QUESTIONNAIRE_RESULT);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getProjectRegions({ projectId: id });
      if (res.status == 200) {
        setRegions(res.data?.regions?.[0]);
      }
    };
    fetchData();
  }, [id]);

  const getOneOffSetting = async (projectId) => {
    try {
      const res = await fetchClassificationOneOffSetting(projectId);
      if (res.status == 200) {
        setOneOffSetting(res.data[0]);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLocation({
          lat: position.coords.latitude,
          long: position.coords.longitude,
        });
      });
    } else {
      console.log("Not Available");
    }
  }, []);

  var times = [];
  var startTime;
  var endTime;
  var survey_start;
  var survey_end;
  // var { maxTimeToFinishPage } = questionnaireJson;

  var allQuestions = questionnaireJson?.pages
    ?.map(({ elements }) => elements)
    ?.map((element) => element)
    ?.map(({ name }) => name);

  allQuestions?.shift();

  useEffect(() => {
    if (id) {
      getWebClientSettings(id);
    }
  }, [id]);

  const handleLoading = (name, status) => {
    setOpenLoading((prev) => ({
      ...prev,
      [name]: status,
    }));
  };

  const getWebClientSettings = async (projectURI) => {
    try {
      handleLoading("initialLoading", true);
      const res = await fetchWebClientSettingsByURL(projectURI);
      console.log("fetchWebClientSettingsByURL", res);
      console.log("res", res);

      if (res?.status === 200) {
        let datePassed = isDateInFuture(
          new Date(res?.data?.surveyExpireDateTime)
        );

        if (!datePassed && res?.data?.surveyExpire) {
          setSurveyExpired({
            date: res?.data?.surveyExpireDateTime
              ? formatAMPM(new Date(res?.data?.surveyExpireDateTime))
              : "",
            message: "Your Survey is Expired!",
            showComponent: true,
            buttonText: "",
            backToHome: false,
            url: BICRUX_URL,
          });
        } else {
          if (res?.data?.userType === SOCIAL_LOIGN) {
            setOpenSocialLoginModal(true);
          } else if (res?.data?.userType === ANONYMOUS_USER) {
            await fetchQuestionnaire(id);
          } else {
            const message = (
              <p>
                You are not authorized to continue.
                <br />
                To login please
                <Link to={LOGIN_PAGE_ROUTE}> Click here. </Link>
              </p>
            );

            setAlertModal({
              title: "Alert",
              message,
              showModel: true,
              buttonText: "Ok",
            });
          }
          setWebClientSetting({
            ...res?.data,
            // expireMinutes: Number(res?.data?.expireMinutes),
          });
        }
      }
    } catch (error) {
      handleLoading("initialLoading", false);
    }
  };

  const fetchQuestionnaire = async (projectURI) => {
    try {
      setLoading(true);
      const res = await fetchProjectSectionByURL(projectURI);
      if (res.status == 200) {
        await getOneOffSetting(res.data.sections[0]?.projectId);
        setSurveyStartTime(getDateTime());
        setSection(res.data.sections[0]);
        setQuestionnaireJson(
          JSON.parse(res.data.sections[0].questionnaire.questionnaireJSON)
        );
        setLoading(false);
      } else {
        toast.error(
          <span style={{ fontSize: "11px", color: "black" }}>
            {ERROR_TEXT}
          </span>,
          {
            position: "top-right",
            autoClose: 5000,
          }
        );
      }
      handleLoading("initialLoading", false);
    } catch (error) {
      handleLoading("initialLoading", false);
      console.log(error);
    }
  };

  const onComplete = async (sender) => {
    // let keys = Object.keys(sender.data);
    // let values = Object.values(sender.data);
    // let result = [];
    // for (let a in keys) {
    //   result.push({
    //     question: keys[a],
    //     answer: values[a],
    //     time: maxTimeToFinishPage > 0 ? times[a] : null,
    //     type: allQuestions[a]?.map((el) => el)?.map(({ type }) => type),
    //   });
    // }
    // if (allQuestions?.length === result?.length) {
    // } else {
    //   for (let j in allQuestions) {
    //     if (
    //       !result?.map(({ question }) => question)?.includes(allQuestions[j])
    //     ) {
    //       result.push({
    //         question: allQuestions[j],
    //         answer: null,
    //         time: maxTimeToFinishPage > 0 ? times[j] : null,
    //         type: allQuestions[j]?.map((el) => el)?.map(({ type }) => type),
    //       });
    //     }
    //   }
    // }

    // const answerJSON = JSON.stringify(sender.data);
    const answerJSON = sender.data;
    // const answerResult = getAnswerResult(sender, times);

    survey_end = new Date();

    let body = {
      visitMonth: oneOffSetting?.name,
      fieldStartDate: oneOffSetting?.fieldStartDate,
      fieldEndDate: oneOffSetting?.fieldEndDate,
      guid: uuid()?.replaceAll("-", ""),
      userId: userAuth ? userAuth?.id : userAuthId, // if user is anonymous then we will pass 742 as a user id
      projectId: section?.projectId,
      sectionId: section?.id,
      subAreaName: regions?.subArea?.name,
      subAreaId: regions?.subArea?.id,
      countryId: regions?.subArea?.countryId,
      stateId: regions?.subArea?.stateId,
      cityId: regions?.subArea?.cityId,
      superAreaId: regions?.subArea?.superAreaId,
      areaId: regions?.subArea?.areaId,
      level: regions?.subArea?.level,
      lat: location.lat,
      lng: location.long,
      LOI:
        (new Date(survey_end).getTime() - new Date(survey_start).getTime()) /
        1000,
      startDateTime: surveyStartTime,
      endDateTime: getDateTime(),
      answerJSON: answerJSON,
      // answerResult: JSON.stringify(answerResult),
      questionnaireId: section?.questionnaire?.id,
      questionnaireVersion: section?.questionnaire?.version,
    };

    let online = window.navigator.onLine;
    if (online) {
      await saveDataOnline(body);
    } else {
      await storeOffline(body);
    }

    // if (webClientSetting?.offlineSupport) {
    //   let online = window.navigator.onLine;
    //   if (online) {
    //     await saveDataOnline(body);
    //   } else {
    //     await storeOffline(body);
    //   }
    // } else {
    //   await saveDataOnline(body);
    // }
    // fetch("https://www.google.com/")
    //   .then((res) => {
    //     saveDataOnline(body);
    //   })
    //   .catch((err) => {
    //     storeOffline(body);
    //   });
  };

  const saveDataOnline = async (body) => {
    setLoading(true);
    const res = await SyncWebClientData(body);
    if (res.status == 200) {
      // navigate("/projects");
      setLoading(false);
      toast.success(
        <span style={{ fontSize: "11px", color: "black" }}>
          {RESPONSE_SAVE_MESSAGE}
        </span>,
        {
          position: "top-right",
          autoClose: 5000,
        }
      );
      handleRemoveQuestionnaireResult();
    } else {
      setLoading(false);
    }
  };

  const onQuestionTimeStart = (e) => {
    startTime = new Date();
  };

  const onQuestionTimeEnd = (e) => {
    endTime = new Date().getTime();
    var seconds = (endTime - startTime) / 1000;
    times.push(seconds);
  };

  const onSurveyStart = () => {
    survey_start = new Date();
  };

  const handleRemoveQuestionnaireResult = () => {
    localStorage.removeItem(QUESTIONNAIRE_RESULT);
    setCloseQuestionnaire(true);
  };

  const storeOffline = async (body) => {
    setLoading(true);
    localStorage.setItem("local_d", JSON.stringify(body));
    handleRemoveQuestionnaireResult();
    // navigate("/projects");
    setLoading(false);
  };

  const handleSuccessGoogleAuth = async (res) => {
    let { email, googleId, name } = res?.profileObj;
    const response = await gmailSignUp({
      email,
      googleId,
      name,
    });
    if (
      response.status === 200 ||
      response.status === 201 ||
      response.status === 202
    ) {
      let token = parseJwt(response.data);
      setUserAuth(token);
      await fetchQuestionnaire(id);
      setOpenSocialLoginModal(false);
      // setRegionModal(true);
    }
    try {
    } catch (error) {
      setOpenSocialLoginModal(false);
      handleLoading("initialLoading", false);
    }
  };
  const handleFailureGoogleAuth = (err) => {
    toast.error(
      <span style={{ fontSize: "11px", color: "black" }}>{ERROR_TEXT}</span>,
      {
        position: "top-right",
        autoClose: 5000,
      }
    );
    console.log("failed:", err);
  };

  const handleGoogleAuth = async () => {
    try {
      const initClient = () => {
        gapi.client.init({
          clientId: GOOGLE_CLIENT_ID,
          scope: "",
        });
      };
      gapi.load("client:auth2", initClient);
    } catch (error) {}
  };

  const componentClicked = () => {};

  const responseFacebook = () => {};

  const handleCloseAlertModel = () => {
    setAlertModal({
      title: "",
      message: "",
      showModel: false,
    });
    navigate(LOGIN_PAGE_ROUTE);
  };
  // Renderer callback with condition
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return "Expired";
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };

  const handleExpiredQuestionnaire = () => {
    let item = localStorage.getItem(QUESTIONNAIRE_RESULT);
    let parse = JSON.parse(item);
    setCloseQuestionnaire(true);
    if (item) {
      onComplete({ data: parse?.answerJSON });
    } else {
      onComplete({ data: {} });
    }
  };

  return (
    <div className="d-flex flex-column">
      {surveyExpired?.showComponent ? (
        <SurveyExpire
          message={surveyExpired?.message}
          url={surveyExpired?.url}
          backToHome={surveyExpired?.backToHome}
          buttonText={surveyExpired?.buttonText}
          date={surveyExpired?.date}
        />
      ) : (
        <>
          <AlertModal
            showModel={alertModal?.showModel}
            handleCloseModal={handleCloseAlertModel}
            message={alertModal?.message}
            title={alertModal?.title}
            buttonText={alertModal?.buttonText}
          />
          {webClientSetting?.userType === SOCIAL_LOIGN &&
          openSocialLoginModal ? (
            <SocialLoginModal
              openSocialLoginModal={openSocialLoginModal}
              setOpenSocialLoginModal={setOpenSocialLoginModal}
              webClientSetting={webClientSetting}
              handleSuccessGoogleAuth={handleSuccessGoogleAuth}
              handleFailureGoogleAuth={handleFailureGoogleAuth}
              handleGoogleAuth={handleGoogleAuth}
              responseFacebook={responseFacebook}
              componentClicked={componentClicked}
            />
          ) : (
            ""
          )}
          {!initialLoading ? (
            <>
              {/* {region != null ? null : (
              <div className="alert alert-warning p-1" role="alert">
                No region has been selected!{" "}
                <button className="btn" onClick={() => setRegionModal(true)}>
                  Select Regio
                </button>
              </div>
            )} */}

              <div
                className={clsx(
                  "container-fluid sticky-top p-2 px-3",
                  styles.main
                )}
              >
                {webClientSetting ? (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-lg-12 col-xs-12 justify-content-between d-flex">
                      <div>
                        {webClientSetting?.logo ? (
                          <img
                            className={clsx("", styles.logo)}
                            alt="project-logo"
                            src={webClientSetting?.logo}
                          />
                        ) : (
                          ""
                        )}

                        <span className="m-2">
                          <span>{section?.project?.projectName}</span>
                        </span>
                      </div>
                      <div className="text-end" style={{ paddingTop: "3px" }}>
                        {webClientSetting?.calculateResponseTime &&
                        webClientSetting?.expireMinutes &&
                        !closeQuestionnaire ? (
                          <Countdown
                            date={
                              Date.now() +
                              Number(webClientSetting?.expireMinutes) * 60000
                            }
                            intervalDelay={0}
                            precision={3}
                            renderer={renderer}
                            onComplete={() => handleExpiredQuestionnaire()}
                          />
                        ) : (
                          ""
                        )}
                        {webClientSetting?.calculateResponseTime &&
                        webClientSetting?.expireMinutes &&
                        closeQuestionnaire ? (
                          <span>
                            {" "}
                            <span id="demo">Expired</span>
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    {!closeQuestionnaire ? (
                      <SurveyCreatorComponent
                        handleStart={onQuestionTimeStart}
                        handleSetQuestion={onQuestionTimeEnd}
                        isEdit={true}
                        handleStarted={onSurveyStart}
                        handleComplete={onComplete}
                        onCompletingHandle={onQuestionTimeEnd}
                        questionnaireJson={questionnaireJson}
                        webClientSetting={webClientSetting}
                        closeQuestionnaire={closeQuestionnaire}
                        projectName={section?.project?.projectName}
                      />
                    ) : webClientSetting?.surveyExpireMessage ? (
                      <SurveyThanks
                        message={webClientSetting?.surveyExpireMessage}
                        date={new Date()}
                        projectName={section?.project?.projectName}
                        url={BICRUX_URL}
                        id={id}
                        takeSuveyAgain={true}
                      />
                    ) : (
                      // <div className={styles.message}>
                      //   <div>{webClientSetting?.surveyExpireMessage}</div>
                      //   <div>
                      //     <a href={`/${id}`}>Click here</a> to submit another
                      //     response.
                      //   </div>
                      // </div>
                      ""
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "90vh" }}
            >
              <img alt="loading" style={{ maxHeight: 100 }} src={loader} />
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default AnonymousSocialQuestionnaire;
