import { createTheme, Dialog, ThemeProvider } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./App.css";
import { GlobalProvider } from "./global_context";
import Root from "./layouts/root";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.css";
import "react-loading-skeleton/dist/skeleton.css";
import cloudAnimation from "./assets/cloud_loading_256.gif";
import { ToastContainer } from "react-toastify";
import "../node_modules/react-toastify/dist/ReactToastify.css";

import { SyncWebClientData } from "./apis";
import { useLocation } from "react-router-dom";
import { GUEST_USER } from "./constants";

function App() {
  const search = useLocation().search;
  // guest user is the user who comes from URL
  const guesUser = new URLSearchParams(search).get(GUEST_USER);
  const [, setOpen] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);

  const theme = createTheme({
    typography: {
      fontFamily: ["sans-serif", "Montserrat"].join(","),
    },
  });

  let _data = JSON.parse(localStorage.getItem("local_d"));

  useEffect(() => {
    // if (_data != null) {

    //   fetch("https://www.google.com/")
    //     .then((res) => {
    //       setDialogOpen(true);
    //       SyncWebClientData(_data).then((res) => {
    //         clearInterval();
    //         setDialogOpen(false);
    //         localStorage.removeItem("local_d");
    //       });
    //     })
    //     .catch((err) => {
    //       setDialogOpen(false);
    //     });
    // }
    if (_data != null && !guesUser) {
      let online = window.navigator.onLine;
      if (online) {
        try {
          setDialogOpen(true);
          SyncWebClientData(_data).then((res) => {
            clearInterval();
            setDialogOpen(false);
            localStorage.removeItem("local_d");
          });
        } catch (error) {
          setDialogOpen(false);
        }
      }
    }
  }, []);

  return (
    <GlobalProvider>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Root />
          <Dialog open={dialogOpen} onClose={() => setOpen(false)}>
            <div className="container bg-white d-flex flex-column align-items-center justify-content-center">
              <img alt="cloud" style={{ maxWidth: 200 }} src={cloudAnimation} />
              <p className="fw-bold p-2 mb-0">Synchronizing</p>
            </div>
          </Dialog>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </ThemeProvider>
    </GlobalProvider>
  );
}

export default App;
