export default(state, action)=>{
    switch(action.type){
       
        case 'LOGIN_USER':
            return{
                ...state,
                auth:action.payload
            }
        case 'LOGOUT_USER':
                return{
                    ...state,
                    auth:null
                }
        case 'LOAD':
                return {...state,loading:action.payload}
        case 'SET_REGION':
                return {...state,region:action.payload}
        case 'SET_REGION_MODAL':
                return {...state,regionModalShow:action.payload}

                
                
        default:
                return state;
    }
}