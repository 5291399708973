import { Button } from "bootstrap/dist/js/bootstrap.bundle";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./style.css";
import { getDashboardUrls } from "../../apis";
function ManageVideo({mediaUrl}) {

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(!show);
  const handleClose = () => setShow(false);
  const [videosURL, setVideosURL] = useState([]);



  useEffect(() => {
    if(mediaUrl){
      setVideosURL(mediaUrl);
    }
  },[mediaUrl])


  const handleURL = (e, index) => {
    debugger;
    let {value} = e.target;
     let temp = [...videosURL];
     temp[index] = value;
     setVideosURL(temp);
  }

  const handleAdd = () => {
    debugger
     let temp = [...videosURL];
     temp.push("");
     setVideosURL(temp);
  }

  const handleDelete = (index) => {
    debugger
     let temp = [...videosURL];
     temp.splice(index, 1);
     setVideosURL(temp);
  }

   const convertToEmbedLink = (regularLink) => {
    // Check if the regular link is a Mega.nz link
    if (regularLink?.startsWith("https://mega.nz/")) {
      // Replace '/file/' with '/embed/'
      const embedLink = regularLink.replace("/file/", "/embed/");
      return embedLink;
    } else {
      // Return an error message if the input link is not a Mega.nz link
      return "Invalid Mega.nz link";
    }
  };
  



//   const [URL, setURL] = useState("");

  return (
    <div className="manage-video mt-2">
      {/* <button className="btn btn-primary" onClick={handleShow}>
        Launch
      </button> */}

      <a href="#" style={{fontSize: "13px", color: "#0D349B"}}
       onClick={handleShow} 
      >Media</a>

      <Modal
        //   {...props}
        show={show}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
      >
        <Modal.Header closeButton className="bg-primary">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-light"
            style={{ fontSize: "14px" }}
          >
            {/* Upload Videos */}
            Media
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {videosURL?.length === 0 && "No Media"}
          {videosURL.map((url, index) => (
            <div className="manage-video-wrapper">
              <div className="d-flex align-items-center">
{/* 
                <div className="me-3">
                       Image/Video
                    </div> */}


                {/* <input
                  type="text"
                  placeholder="paste an image or video URL"
                  className="form-control  me-3"
                  value={url || null}
                  onChange={(e) => handleURL(e, index)}
                /> */}

                <div className="d-flex flex-row flex-wrap">
                  <div className="m-2">
                  <iframe
                    title="Video"
                    width={400}
                    height={250}
                    src={convertToEmbedLink(url?.url)}
                    // src="https://mega.nz/embed/c6NFSBaC#n1-hkoY0aJEylAlUy_ktLUjbXkpXGFVh_fGP3LUI4HU"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    webkitallowfullscreen="true"
                  ></iframe>
                  </div>
                </div>


             

                {/* <video width="140" className="me-3" controls autoplay loop>
                  <source
                    src={url?.url || null}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video> */}

                {/* <img src={url || null} width="140" alt="..img" /> */}

                {/* <video
                              // autoPlay
                              controls
                              width={171}
                              height={89}
                              className="mt-2"
                            >
                              <source src={url} type="video/mp4" />
                            </video> */}
               
               

                {/* <img src={require("../../assets/trash-icon.png")} width='23' alt="" onClick={() => handleDelete(index)} /> */}


              </div>
            </div>
          ))}

          {/* <div  className="text-end">
          <img src={require("../../assets/add-icon.png")} width='23' alt="" onClick={() => handleAdd()} />
          </div> */}

        </Modal.Body>
        <Modal.Footer>
          <button
            //   disabled={loading}
            //   onClick={() => regionSelectHandle()}
            onClick={handleShow} 
            type="button"
            className="btn btn-primary"
          >
            {/* Select */}
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ManageVideo;
