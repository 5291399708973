import React,{createContext,useReducer} from'react';
import reducers from'./reducers'

const initialState={
auth:null,
error:null,
loading:false,
region:null,
regionModalShow:false
}

export const GlobalContext=createContext(initialState);

export const GlobalProvider=({children})=>{
    
    const [state,dispatch]=useReducer(reducers,initialState)

    const login=(user)=>{
        console.log("const login=(user)=>{", user)
        dispatch({
            type:'LOGIN_USER',
            payload:user
        })
    }
    const setLoading=(load)=>{
        dispatch({
            type:'LOAD',
            payload:load
        })
    }
    const logout=()=>{
        localStorage.removeItem('auth-token')
        dispatch({
            type:'LOGOUT_USER',
            payload:null
        })
    }
    const setRegion=(data)=>{
     
        dispatch({
            type:'SET_REGION',
            payload:data
        })
    }
    const setRegionModal=(data)=>{ 
        dispatch({
            type:'SET_REGION_MODAL',
            payload:data
        })
    }

    return(
        <GlobalContext.Provider value={{
            auth:state.auth,loading:state.loading,region:state.region,regionModalShow:state.regionModalShow
            ,login,logout,setLoading,setRegion,setRegionModal,
        }}>
          {children}
        </GlobalContext.Provider>
    )
}
