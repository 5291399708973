import React from "react";

import { Modal } from "react-bootstrap";

const AlertModal = ({
  showModel,
  handleCloseModal,
  message,
  title,
  buttonText,
}) => {
  return (
    <Modal
      show={showModel}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-primary">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-light"
          style={{ fontSize: "14px" }}
        >
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ fontSize: "14px" }}>{message}</div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary btn-lg"
          onClick={() => handleCloseModal()}
        >
          {buttonText}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AlertModal;
