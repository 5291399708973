import React from "react";
import { Link } from "react-router-dom";

const CardComponent = ({
  data: {
    id,
    projectClassification,
    createdBy,
    projectName,
    projectDescription,
    startingDate,
    endingDate,
    studyType,
    projectGUID,
    userName,
  },
}) => {
  const getDate = (date) => {
    let todayDate = new Date(date);
    var newDate =
      todayDate.getFullYear() +
      "-" +
      (todayDate.getMonth() + 1) +
      "-" +
      todayDate.getDate();
    return newDate;
  };

  return (
    <div className="project-detail-card bg-white shadow px-3 py-3 d-flex flex-column justify-content-around align-items-start">
      <div className="w-100 d-flex justify-content-between align-items-center p-2">
        <h5
          style={{
            width: "300px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          className="project-title text-primary"
        >
          {projectName}
        </h5>
        <Link
          to={`/projects/${id}`}
          className="btn-primary btn btn-sm px-2 py-0"
        >
          Start
        </Link>
      </div>
      <div className="project-description text-muted p-2">
        <p className="my-0">
          <span className="fw-bold mx-1">Created By:</span>
          {userName}
        </p>
        <p className="my-0">
          <span className="fw-bold mx-1"> Started On:</span>
          {getDate(startingDate)}
        </p>
        <p className="my-0">
          <span className="fw-bold mx-1"> Study Type:</span>
          {studyType}
        </p>
        <p className="my-0">
          <span className="fw-bold mx-1">Classification:</span>
          {projectClassification}
        </p>
      </div>
      <div className="p-2 project-footer-info w-100 d-flex justify-content-between aling-items-center">
        <span></span>
        <span className="text-primary">
          <i></i>
        </span>
      </div>
    </div>
  );
};

export default CardComponent;
