import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import "./Card.css";

const useStyles = makeStyles((theme) => ({
  padd: {
    padding: theme.spacing(3),
    width: "100%",
    height: "100%",
    borderRadius: "8px",
  },
}));

const Card = ({ title, text, Image, iconColor, size }) => {
  const classes = useStyles();

  return (
    <Grid container className={`custom-card-component ${classes.padd}`}>
      <Grid item xs={12} direction="row">
        <Typography
          color="primary"
          variant="caption"
          style={{ margin: "20px 0px", fontWeight: "bold" }}
        >
          {title}
        </Typography>
        <hr />
        <Grid
          container
          xs={12}
          style={{
            background: "#f9f9f9",
            borderLeft: "4px solid blue",
            padding: "10px",
          }}
        >
          <Grid item xs={6} style={{ paddingLeft: "4px" }}>
            <Typography
              variant={size || "h6"}
              style={{ fontSize: "18px" }}
              color="primary"
            >
              {text}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ display: "flex", justifyContent: "end" }}>
            <img alt="img" src={Image} width="30px" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Card;
