import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { FACEBOOK_AUTH_ID, GOOGLE_CLIENT_ID } from "../../constants";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { gapi } from "gapi-script";
import facebookIcon from "../../assets/facebook-icon.svg";
import styles from "./SocialLoginModal.module.css";
import clsx from "clsx";
const SocialLoginModal = ({
  openSocialLoginModal,
  setOpenSocialLoginModal,
  webClientSetting,
  handleSuccessGoogleAuth,
  handleFailureGoogleAuth,
  handleGoogleAuth,
  componentClicked,
  responseFacebook,
}) => {
  return (
    <Modal
      show={openSocialLoginModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-primary">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-light"
          style={{ fontSize: "14px" }}
        >
          Select Login
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <div className="fontSize_11px ">
          {webClientSetting?.social?.Gmail ? (
            <div className="text-center mt-3" onClick={handleGoogleAuth}>
              {/* <GoogleLogin
                // className={clsx(styles.authIcon, styles.icon)}
                clientId={GOOGLE_CLIENT_ID}
                buttonText=""
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={"single_host_origin"}
              /> */}
              <GoogleLogin
                className={clsx(styles.googleIcon)}
                clientId={GOOGLE_CLIENT_ID}
                buttonText={
                  <span className={clsx(`fontSize_11px`, styles.paddLeft)}>
                    Login With Google
                  </span>
                }
                onSuccess={handleSuccessGoogleAuth}
                onFailure={handleFailureGoogleAuth}
                cookiePolicy={"single_host_origin"}
              />

              {/* <img className={styles.icon} src={googleIcon} /> */}
            </div>
          ) : (
            ""
          )}
          {webClientSetting?.social?.Facebook ? (
            <div className="text-center">
              {/* <FacebookLogin
                // cssClass={styles.facebookIcon}
                textButton=""
                // icon={<img className={clsx(styles.icon)} src={facebookIcon} />}
                appId={FACEBOOK_AUTH_ID}
                // autoLoad={true}
                fields="name,email,picture"
                onClick={componentClicked}
                callback={responseFacebook}
              /> */}
              <FacebookLogin
                icon={
                  <img
                    className={clsx(styles.icon, styles.fbIcon)}
                    src={facebookIcon}
                  />
                }
                buttonText={
                  <span className="fontSize_11px">Login With Facobook</span>
                }
                cssClass={styles.facebookIcon}
                appId={FACEBOOK_AUTH_ID}
                fields="name,email,picture"
                onClick={componentClicked}
                callback={responseFacebook}
              />
              ,
            </div>
          ) : (
            ""
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <button
          type="button"
          className="btn btn-secondary"
          onClick={() => setOpenSocialLoginModal(false)}
        >
          Close
        </button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default SocialLoginModal;
