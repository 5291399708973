import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";
import React, { useContext, useState } from "react";
import logo from "../../assets/biCruxTransparentLogo.png";
import { GlobalContext } from "../../global_context";
import { Offline, Online } from "react-detect-offline";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import bootstrap from "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";

export default function Header() {
  const { logout, region, setRegionModal } = useContext(GlobalContext);
  const [notifyOnline, setNoifyOnline] = useState(true);

  const closeNotify = () => {
    setNoifyOnline(false);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <SwitchTransition mode={"out-in"}>
        <CSSTransition
          key={notifyOnline}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div className="button-container">
            {notifyOnline ? (
              <div className="online-status">
                <Offline>
                  <div className="bg-danger shadow text-white px-2 py-2">
                    Internet disconnected!{" "}
                    <button onClick={closeNotify} className="btn text-light">
                      X
                    </button>
                  </div>
                </Offline>
              </div>
            ) : null}
          </div>
        </CSSTransition>
      </SwitchTransition>

      <AppBar
        style={{ background: "#061b7f", height: "42px" }}
        position="static"
      >
        <Toolbar variant="dense" style={{ minHeight: "40px" }}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img
              alt="header-logo"
              style={{
                height: "28px",
                width: "92px",
                position: "relative",
                top: "-3px",
              }}
              src={logo}
            />
            <span
              style={{
                margin: 10,
                fontSize: 14,
                fontWeight: "bold",
                letterSpacing: 0.5,
                color: "#fffff",
              }}
            >
              {/* (Client) */}
            </span>
          </Typography>

          <Button
            color="inherit"
            style={{
              fontSize: "14px",
              textTransform: "capitalize",
              fontFamily: "Montserrat",
            }}
            onClick={logout}
          >
            Log Out
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
