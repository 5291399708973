import React, { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { GUEST_USER } from "../constants";
import { GlobalContext } from "../global_context";
import AppRoutes from "../routes";
import { parseJwt } from "../utils";



const Root = () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  // guest user is the user who comes from URL
  const guesUser = new URLSearchParams(search).get(GUEST_USER);
  const { login } = useContext(GlobalContext);

  useEffect(() => {
   console.log("const { login } = useContext(GlobalContext); root", login);
  }, [login]);

  useEffect(() => {
    const token = localStorage.getItem("auth-token");
    if (guesUser) {
      return;
    } else {
      if (token) {
        login(parseJwt(token));
        navigate("/projects");
      }
    }
  }, []);

  return (
    <div>
      <AppRoutes />
    </div>
  );
};

export default Root;
